import { types } from 'mobx-state-tree';
import Cookies from 'js-cookie';
import i18next from "i18next";

import ApiActionFactory from '../api/api-action-factory';
import { isDev } from '../utils/common';
import StoreFactory from './factories/store';
import { SITE_PROJECT_ID } from '../constants';
import LoginInfoModel from './user-store/models/login-info-model';
import AuthExpirationModel from './user-store/models/auth-expiration-model';
import ApiResponseModel from './models/api-response-model';
import UserInfoModel from './user-store/models/user-info-model';
import UserMethodListModel from './user-store/models/user-method-list-model';
import RoleListModel from './user-store/models/role-list-model';
import RegisterUserModel from './user-store/models/register-user-model';
import PasswordTokenModel from './user-store/models/password-token-model';
import CallCacheModel from './models/call-cache-model';
import AllUserInfoModel from './user-store/models/all-user-info-model';
import SiteListModel from './user-store/models/site-list-model';

import {
  PERFORM_USER_LOGIN,
  PERFORM_USER_LOGOUT,
  USER_HANDSHAKE,
  VERIFY_MFA,
  UPDATE_PASSWORD,
  GET_USER_INFO,
  GET_USER_METHOD_LIST,
  GET_ROLE_LIST,
  REGISTER_USER,
  RESET_PASSWORD_WITH_TOKEN,
  REQUEST_PASSWORD_TOKEN,
  GET_ALL_USER_INFO_LIST,
  GET_SITE_LIST,
  GET_USER_SITE_LIST,
  CREATE_USER_SITE_SECURITE,
} from '../api/endpoints';
import ApplicationStore from './application-store';

const addErrorSnackbar = (message) => {
  console.error(message);
  ApplicationStore.addSnackbar({
    message,
    type: 'error',
    duration: null,
  });
};

const User = StoreFactory({
  modelName: 'UserStore',
  models: [
    ApiActionFactory({
      performUserLogin: {
        endpoint: PERFORM_USER_LOGIN,
        target: 'userInfo',
      },
      performUserLogout: {
        endpoint: PERFORM_USER_LOGOUT,
      },
      verifyMFA: {
        endpoint: VERIFY_MFA,
        target: 'userInfo',
      },
      userHandshake: {
        endpoint: USER_HANDSHAKE,
        target: 'handshakeInfo', // Cannot use userInfo as target because it will overwrite the existing userInfo
        onLoad(self) {
          self.setAuthExpiration(
            self.handshakeInfo?.Item?.authExpiration || null
          );
        },
      },
      updatePassword: {
        endpoint: UPDATE_PASSWORD,
        target: 'updatePasswordResponse',
      },
      getUserInfo: {
        endpoint: GET_USER_INFO,
        target: 'accountInfoResponse',
        onLoad(self) {
          self.callCache.refreshDate('retrieveUserInfo');
        }
      },
      getUserMethodList: {
        endpoint: GET_USER_METHOD_LIST,
        target: 'userMethodListResponse',
      },
      getRoleList: {
        endpoint: GET_ROLE_LIST,
        target: 'roleListResponse',
        onLoad(self) {
          self.callCache.refreshDate('retrieveRoleList');
        }
      },
      registerUser: {
        endpoint: REGISTER_USER,
        target: 'registerUserResponse',
        onError(self, error) {
          self.setRegisterUserResponse(
            RegisterUserModel.create({
              Success: false,
              ErrorMessage: error?.response?.data?.message || error?.message,
            })
          );
        },
      },
      resetPasswordWithToken: {
        endpoint: RESET_PASSWORD_WITH_TOKEN,
        target: 'resetPasswordResponse',
        onError(self, error) {
          self.setResetPasswordResponse(
            ApiResponseModel.create({
              Success: false,
              ErrorMessage: error?.response?.data?.message || error?.message,
            })
          );
        },
      },
      requestPasswordToken: {
        endpoint: REQUEST_PASSWORD_TOKEN,
        target: 'requestPasswordTokenResponse',
        onError(self, error) {
          self.setResetPasswordResponse(
            ApiResponseModel.create({
              Success: false,
              ErrorMessage: error?.response?.data?.message || error?.message,
            })
          );
        },
      },
      getAllUserInfoList: {
        endpoint: GET_ALL_USER_INFO_LIST,
        target: 'getAllUserInfoListResponse',
      },
      getSiteList: {
        endpoint: GET_SITE_LIST,
        target: 'getSiteListResponse',
        onLoad(self) {
          self.callCache.refreshDate('retrieveSiteList');
        }
      },
      getUserSiteList: {
        endpoint: GET_USER_SITE_LIST,
        target: 'getUserSiteListResponse',
      },
      createUserSiteSecurite: {
        endpoint: CREATE_USER_SITE_SECURITE,
        target: 'createUserSiteSecuriteResponse',
      },
    }),
  ],
  modelStructure: {
    rememberMe: types.optional(types.boolean, false),
    userInfo: types.maybeNull(LoginInfoModel),
    handshakeInfo: types.maybeNull(AuthExpirationModel),
    updatePasswordResponse: types.maybeNull(ApiResponseModel),
    accountInfoResponse: types.maybeNull(UserInfoModel),
    userMethodListResponse: types.maybeNull(UserMethodListModel),
    roleListResponse: types.maybeNull(RoleListModel),
    registerUserResponse: types.maybeNull(RegisterUserModel),
    resetPasswordResponse: types.maybeNull(ApiResponseModel),
    requestPasswordTokenResponse: types.maybeNull(PasswordTokenModel),
    getAllUserInfoListResponse: types.maybeNull(AllUserInfoModel),
    getSiteListResponse: types.maybeNull(SiteListModel),
    getUserSiteListResponse: types.maybeNull(SiteListModel),
    createUserSiteSecuriteResponse: types.maybeNull(AuthExpirationModel),
    isLoading: false,
    loadingCalls: types.frozen(new Set()),
    loggingIn: false, // Needs this to prevent isLoggedIn from returning true when logging is still in progress
    callCache: CallCacheModel,
  },
})
  .views((self) => ({
    get authKey() {
      return self.userInfo?.Item?.authKey || null;
    },
    get authExpiration() {
      return self.userInfo?.Item?.authExpiration || null;
    },
    get userId() {
      return self.userInfo?.Item?.userId || null;
    },
    get twoFactorAuthRequired() {
      return self.userInfo?.Item?.twoFactorAuthRequired || false;
    },
    get email() {
      return self.userInfo?.Item?.email || null;
    },
    get userRoleId() {
      return self.userInfo?.Item?.userRoleId || null;
    },
    get accountInfo() {
      return self.accountInfoResponse?.Item?.userInfo || null;
    },
    get userMethodList() {
      return (
        self.userMethodListResponse?.Item?.controllerMethodList.map(
          (method) => method.MethodName
        ) || null
      );
    },
    get roleList() {
      return self.roleListResponse?.Item?.roleInfoList || null;
    },
    get allUserInfoList() {
      return self.getAllUserInfoListResponse?.Item?.userInfoList || null;
    },
    get siteList() {
      return self.getSiteListResponse?.Item?.siteList || null;
    },
    get userSiteList() {
      return self.getUserSiteListResponse?.Item?.siteList || null;
    },
    isLoggedIn() {
      return (
        self.authKey !== null &&
        self.authExpiration !== null &&
        new Date(self.authExpiration) > new Date() &&
        self.userId !== null &&
        self.twoFactorAuthRequired === false &&
        self.loggingIn !== true
      );
    },
    isDataLoading(responsePropertyName) {
      return self.loadingCalls.has(responsePropertyName);
    }
  }))
  .actions((self) => ({
    afterCreate() {
      const authKey = Cookies.get('authKey');
      const authExpiration = Cookies.get('authExpiration');
      const userId = Cookies.get('userId');
      const rememberMe = Cookies.get('rememberMe');
      const userRoleId = Cookies.get('userRoleId');

      if (
        !authKey ||
        !authExpiration ||
        !userId ||
        !rememberMe ||
        !userRoleId
      ) {
        return;
      }

      self.userInfo = {
        Item: {
          userId: parseInt(userId),
          userRoleId: parseInt(userRoleId),
          authKey,
          authExpiration,
        },
      };

      self.rememberMe = rememberMe === 'true';

      userHandshakeBeforeExpiration(self);
    },
    async login(userName, password, rememberMe) {
      self.setLoggingIn(true);

      await self.performUserLogin({
        userName,
        password,
        siteProjectId: SITE_PROJECT_ID,
      });

      if (self.userInfo.Success) {
        self.setRememberMe(rememberMe);

        if (self.userInfo?.Item?.twoFactorAuthRequired) {
          return self.userInfo;
        }

        await saveAuthInfoInCookies(self);

        // Call must be made after saving auth info in cookies
        self.retrieveUserMethodList();
      }

      self.setLoggingIn(false);

      return self.userInfo;
    },
    async verify2FA(code, duration) {
      if (!self.userInfo?.Item?.userId) {
        addErrorSnackbar('User ID not found');

        self.setLoggingIn(false);
        return;
      }

      if (typeof code !== 'string' || code.length !== 6) {
        addErrorSnackbar('Invalid MFA code');

        self.setLoggingIn(false);
        return;
      }

      if (typeof duration !== 'number' || duration < 0 || duration > 30) {
        addErrorSnackbar('Invalid MFA duration');

        self.setLoggingIn(false);
        return;
      }

      await self.verifyMFA({
        userId: self.userInfo.Item.userId,
        siteProjectId: SITE_PROJECT_ID,
        userEnteredCode: code,
        mfaExpirationInDay: duration,
        webSiteName: i18next.t('ccrn:app-name'),
      });

      if (self.userInfo.Success) {
        self.setTwoFactorAuthRequired(false);
        saveAuthInfoInCookies(self);
        self.retrieveUserMethodList();
      }

      self.setLoggingIn(false);

      return self.userInfo;
    },
    logout() {
      self.performUserLogout();

      Cookies.remove('authKey');
      Cookies.remove('authExpiration');
      Cookies.remove('userId');
      Cookies.remove('rememberMe');
      Cookies.remove('userRoleId');

      self.resetStore();
    },
    updateUserPassword(currentPassword, newPassword) {
      self.updatePassword({
        oldPassword: currentPassword,
        password: newPassword,
        siteProjectId: SITE_PROJECT_ID,
      });
    },
    retrieveUserInfo() {
      if (self.callCache.mustRefresh('retrieveUserInfo')) {
        self.getUserInfo({
          siteProjectId: SITE_PROJECT_ID,
          userId: self.userId,
        });
      }
    },
    retrieveUserMethodList() {
      self.getUserMethodList({
        roleId: self.userRoleId,
      });
    },
    resetUpdatePasswordResponse() {
      self.updatePasswordResponse = null;
    },
    setRememberMe(rememberMe) {
      self.rememberMe = rememberMe;
    },
    setTwoFactorAuthRequired(twoFactorAuthRequired) {
      if (!self?.userInfo?.Item) {
        addErrorSnackbar('User info not found. Cannot set two factor required.');
        return;
      }

      self.userInfo.Item.twoFactorAuthRequired = twoFactorAuthRequired;
    },
    setAuthExpiration(authExpiration) {
      if (!self?.userInfo?.Item) {
        addErrorSnackbar('User info not found. Cannot set auth expiration.');
        return;
      }

      self.userInfo.Item.authExpiration = authExpiration;
    },
    retrieveRoleList() {
      if (self.callCache.mustRefresh('retrieveRoleList')) {
        self.getRoleList({
          roleId: self.userRoleId,
          siteProjectId: SITE_PROJECT_ID,
        });
      }
    },
    createAccount({ username, fullName, email, roleId }) {
      const registerUserParams = {
        userName: username,
        name: fullName,
        password: '',
        email,
        roleId,
        siteProjectId: SITE_PROJECT_ID,
        webSiteName: i18next.t('ccrn:app-name'),
        webSiteUrl: window.location.origin,
        signupUrl: `${window.location.origin}/account/reset-password`,
      };

      self.registerUser(registerUserParams);
    },
    resetRegisterUserResponse() {
      self.registerUserResponse = null;
    },
    setRegisterUserResponse(response) {
      self.registerUserResponse = response;
    },
    resetPassword(token, userName, password) {
      self.resetPasswordWithToken({
        token,
        userName,
        password,
        siteProjectId: SITE_PROJECT_ID,
      });
    },
    resetResetPasswordResponse() {
      self.resetPasswordResponse = null;
    },
    clearResetPasswordResponseError() {
      if (self.resetPasswordResponse?.Success === false) {
        self.resetResetPasswordResponse();
      }
    },
    setResetPasswordResponse(response) {
      self.resetPasswordResponse = response;
    },
    setLoggingIn(loggingIn) {
      self.loggingIn = loggingIn;
    },
    requestPasswordReset(email, userName) {
      self.requestPasswordToken({
        email,
        userName,
        siteProjectId: SITE_PROJECT_ID,
        webSiteName: i18next.t('ccrn:app-name'),
        signupUrl: `${window.location.origin}/account/reset-password`,
      });
    },
    retrieveAllUserInfoList() {
      self.getAllUserInfoList({
        siteProjectId: SITE_PROJECT_ID,
      });
    },
    retrieveSiteList() {
      if (self.callCache.mustRefresh('retrieveSiteList')) {
        self.getSiteList();
      }
    },
    retrieveUserSiteList(userId) {
      if (!userId) {
        addErrorSnackbar('User ID not found');
        return;
      }

      self.getUserSiteList({
        siteProjectId: SITE_PROJECT_ID,
        userId: userId,
      });
    },
    resetUserSiteList() {
      self.getUserSiteListResponse = null;
    },
    grantSitePermissions(userId, siteIds) {
      self.createUserSiteSecurite({
        userId,
        siteIds,
        siteProjectId: SITE_PROJECT_ID,
      });
    },
    resetCreateUserSiteSecuriteResponse() {
      self.createUserSiteSecuriteResponse = null;
    },
    resetStore() {
      self.userInfo = null;
      self.handshakeInfo = null;
      self.updatePasswordResponse = null;
      self.accountInfoResponse = null;
      self.userMethodListResponse = null;
      self.roleListResponse = null;
      self.registerUserResponse = null;
      self.resetPasswordResponse = null;
      self.requestPasswordTokenResponse = null;
      self.getAllUserInfoListResponse = null;
      self.getSiteListResponse = null;
      self.getUserSiteListResponse = null;
      self.createUserSiteSecuriteResponse = null;
      self.loggingIn = false;
      self.callCache.reset();
    },
  }));

const UserStore = User.create();

if (window && isDev()) {
  window.UserStore = UserStore || {};
}

export default UserStore;

async function saveAuthInfoInCookies(self) {
  if (!self.authKey) {
    addErrorSnackbar('Auth key not found');
    return;
  }

  if (!self.authExpiration) {
    addErrorSnackbar('Auth expiration not found');
    return;
  }

  if (!self.userId) {
    addErrorSnackbar('User ID not found');
    return;
  }

  if (!self.userRoleId) {
    addErrorSnackbar('User role ID not found');
    return;
  }

  const expirationDate = new Date(self.authExpiration);
  const cookieExpirationDate = self.rememberMe ? expirationDate : undefined;

  setCookie('authKey', self.authKey, cookieExpirationDate);
  setCookie('authExpiration', self.authExpiration, cookieExpirationDate);
  setCookie('userId', self.userId, cookieExpirationDate);
  setCookie('rememberMe', self.rememberMe, cookieExpirationDate);
  setCookie('userRoleId', self.userRoleId, cookieExpirationDate);

  userHandshakeBeforeExpiration(self);
}

function setCookie(name, value, expirationDate) {
  Cookies.set(name, value, {
    expires: expirationDate,
    secure: true,
    sameSite: 'strict',
  });
}

function userHandshakeBeforeExpiration(self) {
  if (!self.authKey) {
    return;
  }

  // Call handshake API 1 minute before expiration
  const expirationDate = new Date(self.authExpiration);
  const currentTime = new Date();
  const timeDiff = expirationDate.getTime() - currentTime.getTime();

  if (timeDiff > 0) {
    console.log('Handshake API will be called in', Math.max(timeDiff - 60000, 0), 'ms');
    
    setTimeout(async () => {
      if (!self.authKey) {
        return;
      }

      await self.userHandshake({ withRefresh: true });
      if (new Date(self.authExpiration).getTime() === expirationDate.getTime()) {
        addErrorSnackbar('Auth expiration did not change.');
        return;
      }

      console.log('Handshake API called');
      saveAuthInfoInCookies(self);
    }, Math.max(timeDiff - 60000, 0));
  }
}
