import { types } from 'mobx-state-tree';

import { API_CALL_CACHE_DURATION } from '../../constants';

const CallCacheModel = types.optional(
  types
    .model({
      cache: types.map(
        types.model({
          lastCalled: types.optional(types.Date, new Date()),
        })
      ),
    })
    .actions((self) => ({
      mustRefresh(key) {
        return (
          !self.cache.has(key) ||
          new Date() - self.cache.get(key)?.lastCalled > API_CALL_CACHE_DURATION
        );
      },
      refreshDate(key) {
        self.cache.set(key, {
          lastCalled: new Date(),
        });
      },
      reset() {
        self.cache.clear();
      }
    })),
  {}
);

export default CallCacheModel;
